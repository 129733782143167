import React, { MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react'
import { Typography, Box, Card, Skeleton } from '@mui/material'
import 'firebase/auth'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table'
import { getWealthboxWorkflowTemplates } from 'service/integrations/wealthboxApi'
import { useQuery } from '@tanstack/react-query'
import { ClientDetail, WealthboxVisibility, WealthboxWorkflow, WealthboxWorkflowTemplate } from 'client'
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table'

interface Props {
  client?: ClientDetail
  workflowStart: MutableRefObject<WealthboxWorkflow[]>
  visibility: WealthboxVisibility
  rowSelection: MRT_RowSelectionState
  setRowSelection: OnChangeFn<RowSelectionState>
}
interface CellProps {
  renderedCellValue: ReactNode
  row: MRT_Row<WealthboxWorkflowTemplate>
}

export default function WealthboxWorkflowsStart({ client, workflowStart, visibility, rowSelection, setRowSelection }: Props) {

  const { data, isLoading } = useQuery({
    queryKey: ['getWealthboxWorkflowTemplates'], queryFn: getWealthboxWorkflowTemplates
  })

  useEffect(() => {
    if (!client)
      return
    workflowStart.current = table.getSelectedRowModel().rows.map((row) => {
      const res: WealthboxWorkflow = {
        linked_to: {
          id: client.wealthbox_id,
          name: `${client.first_name ?? ''} ${client.last_name ?? ''}`,
        },
        workflow_template: row.original,
        visible_to: visibility,
        workflow_milestones: row.original.workflow_milestones
      }
      return res
    })
  }, [rowSelection])

  const columns = useMemo<MRT_ColumnDef<WealthboxWorkflowTemplate>[]>(
    () => [
      {
        accessorKey: 'name', // access nested data with dot notation
        header: 'Name',
        size: 300,
        Cell: CellName
      },
      {
        accessorKey: 'steps',
        header: 'Steps',
        size: 10,
        Cell: CellSteps,
      },
      {
        accessorKey: 'kind',
        header: 'Workflow For',
        size: 10,
      }
    ],
    [],
  )

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableFullScreenToggle: false,
    positionGlobalFilter: 'left',
    enableHiding: false,
    enableDensityToggle: false,
    enableSelectAll: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    initialState: {
      density: 'compact',
      columnOrder: [
        ...columns.map((col) => col.accessorKey as string),
        'mrt-row-select', // ID of the selection column
      ],
      pagination: {
        pageIndex: 0,
        pageSize: 5, // Set initial rows per page to 5
      },
    },
    localization: {
      select: 'Start',
    },
    positionActionsColumn: "last"
  })

  return (
    <Box>
      <Typography>Start a Workflow by selecting a Workflow Template</Typography>
      <Box sx={{ padding: 1 }} />

      {isLoading || !data
        ? <Skeleton variant="rectangular" width={'100%'} height={100} />
        : data.length == 0
          ? <Typography>No Workflow Templates found.</Typography>
          : <MaterialReactTable table={table} />
      }

      <Typography variant='caption'>Milestones are set 1 day ahead by default.</Typography>
    </Box>
  )
}

function CellName({ row }: CellProps) {
  const maxLength = 40
  const text = row.original.name ?? ''
  const short = text.length > maxLength ? text.slice(0, maxLength) + "..." : text
  return (
    <Typography>
      {short}
    </Typography>
  )
}

function CellSteps({ row }: CellProps) {
  const steps = row.original.workflow_steps?.length
  return (
    <Typography>
      {steps}
    </Typography>
  )
}
