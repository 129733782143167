import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { Alert, Box, Button, Card, Checkbox, Dialog, IconButton, Link, Snackbar, SxProps, Typography } from '@mui/material'
import { logErr, logInfo } from '../../../log'
import { ActionItem, CRM, EventBase, SalesforceApp } from '../../../client'
import { Media, salesforceAppIconUrl } from '../../../service/media'
import { gSx } from '../../../styles/Theme'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { addSalesforceClientNote } from 'service/integrations/salesforceApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { htmlToPlainText } from 'utils'
import SelectTasks, { MasterTask } from '../SelectTasks'
import SalesforceCrmClientCard from './SalesforceCrmClientCard'

interface Props {
  open: boolean
  onClose: () => void
  event?: EventBase
  eventSummaryHtml?: string
  app: SalesforceApp
}

export default function SalesforceDialog({ open, onClose, event, eventSummaryHtml, app }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const eventId = event?.id
  const hasClientEmail = event?.client?.email ?? false
  const { isBlocked } = useIsBlockedByPlan(true)

  const [selectNotes, setSelectNotes] = useState(true)
  const [masterTasks, setMasterTasks] = useState<MasterTask[]>([])

  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const isSent = (event?.salesforce_sent && app == SalesforceApp.SALESFORCE)
    || (event?.xlr8_sent && app == SalesforceApp.XLR8)
    || (event?.practifi_sent && app == SalesforceApp.PRACTIFI)

  useEffect(() => {
    if (open) {
      logInfo(`${app} NoteSender Dialog Open`)
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  const disableSend = isBlocked || !hasClientEmail || busy

  async function onSend() {
    setBusy(true)
    setErr(undefined)
    if (event?.client?.email && eventSummaryHtml) {
      const notes = selectNotes
        ? htmlToPlainText(eventSummaryHtml ?? '')
        : undefined
      const tasks: ActionItem[] = masterTasks.filter(m => m.isSelected)
      const res = await addSalesforceClientNote(event?.client, event.id, app, tasks, notes)
      if (res instanceof Error) {
        if (res.message.includes('INVALID_SESSION_ID'))
          setErr('Unauthorized. Please sign in again.')
        else {
          const add = "\n Please try again. If error persists, sign out and back in. If issue remains, contact support."
          setErr(res.message + add)
        }

      } else {
        queryClient.invalidateQueries(['getEvent', eventId])
        setSnackSuccess(true)
        onClose()
      }
    }
    setBusy(false)
  }

  function onSettings() {
    if (app == SalesforceApp.PRACTIFI) {
      navigate(Path.PractifiSettings)
      return
    }
    if (app == SalesforceApp.XLR8) {
      navigate(Path.Xlr8Settings)
      return
    }
    navigate(Path.SalesforceSettings)
  }

  function toBilling() {
    navigate(Path.Billing)
  }

  if (!eventId) {
    logErr('missing event id')
    return null
  }

  return (
    <>
      <Dialog open={open} onClose={() => onClose()}>
        <Box sx={sxContainer}>

          <Box sx={sxTop}>
            <Box sx={sxTopLeft} >
              <Box
                component="img"
                sx={icon}
                src={salesforceAppIconUrl(app)}
                alt={app}
              />
              <Typography variant='h3'>
                Send Meeting Summary to {app}
              </Typography>
            </Box>

            <IconButton aria-label='close' onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={sxContent}>
            <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 240px)' }}>
              {isBlocked &&
                <Typography sx={sxText}>{`Please `}
                  <Link onClick={toBilling}>Upgrade your plan to use </Link>{app}
                </Typography>
              }
              <Box sx={{ padding: 1 }} />
              <Typography>The complete Meeting Summary, including all tasks, will be stored as a {app} Event. Selected tasks will also be saved as {app} Tasks.</Typography>
              <Box sx={{ padding: 1 }} />

              <SalesforceCrmClientCard event={event} app={app} />

              <Card>
                <Typography variant='h5'>Meeting Summary</Typography>
                <Box sx={{ padding: 1 }} />
                <Box sx={gSx.Row}>
                  <Checkbox
                    sx={{ padding: 0, marginLeft: 1, marginRight: 2 }}
                    checked={selectNotes}
                    onChange={(event, value) => setSelectNotes(value)}
                    disabled={busy}
                  />
                  <Typography>Include Meeting Summary as a {app} Event</Typography>
                </Box>
              </Card>

              <Card>
                <Typography variant='h5'>Select Tasks</Typography>
                <Typography>All selected items will be saved as {app} Tasks.</Typography>
                <Typography variant='caption'>Please note that all Tasks created will initially be assigned to you. To assign them to other team members, edit the Task details within your {app} CRM.</Typography>

                <SelectTasks
                  eventId={eventId}
                  masterTasks={masterTasks}
                  setMasterTasks={setMasterTasks}
                  disabled={busy}
                  crm={CRM.SALESFORCE}
                />
              </Card >
            </Box>

            <Box sx={sxBottom}>

              {isSent &&
                <Box sx={{ ...gSx.Row, paddingBottom: 2 }}>
                  <CheckIcon fontSize='small' sx={{ paddingX: 1, color: 'success.light' }} />
                  <Typography color='success.light'>Meeting details has already been sent</Typography>
                </Box>
              }

              <LoadingButton
                variant='contained'
                loading={busy}
                disabled={disableSend}
                onClick={onSend}
                sx={{ paddingX: 10 }}
              >
                Send to {app}
              </LoadingButton>

              {
                err && <Box sx={{ paddingY: 2 }}>
                  <Typography sx={{ color: 'tomato' }}>{err}</Typography>
                  <Typography sx={{ color: 'tomato' }}>
                    Please refresh browser and try again. Re-sign into {app} may also fix the issue. If issue remains, contact support.
                  </Typography>
                  <Button
                    onClick={onSettings}
                    variant='outlined'
                  >
                    {app} Settings
                  </Button>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Dialog >
      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          {app} Meeting Summary and Tasks Sent
        </Alert>
      </Snackbar>
    </>
  )
}

const sxContainer: SxProps = {
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  padding: 2,
  minWidth: 400
}

const sxTop: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start'
}

const sxTopLeft: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}

const sxContent: SxProps = {
  flex: 1,
  overflow: 'auto',
}

const sxBottom: SxProps = {
  flex: 1,
  flexDirection: 'column',
  position: 'sticky',
  bottom: 0,
  paddingY: '5px',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}
