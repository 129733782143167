/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionItem } from '../models/ActionItem';
import type { ActionItemList } from '../models/ActionItemList';
import type { AssigneeInfo } from '../models/AssigneeInfo';
import type { Bot } from '../models/Bot';
import type { BotRequest } from '../models/BotRequest';
import type { ClientData } from '../models/ClientData';
import type { EventBase } from '../models/EventBase';
import type { EventInDb } from '../models/EventInDb';
import type { EventSearchParams } from '../models/EventSearchParams';
import type { ListEvents } from '../models/ListEvents';
import type { ModuleIdentifier } from '../models/ModuleIdentifier';
import type { Origin } from '../models/Origin';
import type { SentimentDoc } from '../models/SentimentDoc';
import type { SharedUser } from '../models/SharedUser';
import type { SummaryBase } from '../models/SummaryBase';
import type { SummaryProcessingStatus } from '../models/SummaryProcessingStatus';
import type { TranscriptBase } from '../models/TranscriptBase';
import type { Video } from '../models/Video';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Finmate Super User Events
     * @returns ListEvents Successful Response
     * @throws ApiError
     */
    public finmateSuperUserEvents(): CancelablePromise<ListEvents> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/finmate_super_user_events',
        });
    }

    /**
     * Get Events
     * @param pageNum
     * @param requestBody
     * @param isShared
     * @returns ListEvents Successful Response
     * @throws ApiError
     */
    public getEventsV2(
        pageNum: number,
        requestBody: EventSearchParams,
        isShared?: boolean,
    ): CancelablePromise<ListEvents> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/get_events',
            query: {
                'page_num': pageNum,
                'is_shared': isShared,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Available Modules
     * @returns ModuleIdentifier Successful Response
     * @throws ApiError
     */
    public getAvailableModules(): CancelablePromise<Array<ModuleIdentifier>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/modules',
        });
    }

    /**
     * Get Event
     * @param eventId
     * @returns EventBase Successful Response
     * @throws ApiError
     */
    public getEvent(
        eventId: string,
    ): CancelablePromise<EventBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteEvent(
        eventId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/events/{event_id}',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Raw
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getEventTranscriptRaw(
        eventId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/transcript/raw',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Transcript Sentences
     * @param eventId
     * @returns TranscriptBase Successful Response
     * @throws ApiError
     */
    public getEventTranscript(
        eventId: string,
    ): CancelablePromise<TranscriptBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/transcript',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Transcript Speaker
     * @param eventId
     * @param speakerId
     * @param speakerEdited
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateEventTranscriptSpeaker(
        eventId: string,
        speakerId: string,
        speakerEdited: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{event_id}/transcript/speaker',
            path: {
                'event_id': eventId,
            },
            query: {
                'speaker_id': speakerId,
                'speaker_edited': speakerEdited,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Video
     * @param eventId
     * @returns Video Successful Response
     * @throws ApiError
     */
    public getEventVideo(
        eventId: string,
    ): CancelablePromise<Video> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/video',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Summary Base
     * @param eventId
     * @returns SummaryBase Successful Response
     * @throws ApiError
     */
    public getEventSummaryBase(
        eventId: string,
    ): CancelablePromise<SummaryBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/summary/base',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Summary Status
     * @param eventId
     * @returns SummaryProcessingStatus Successful Response
     * @throws ApiError
     */
    public getEventSummaryStatus(
        eventId: string,
    ): CancelablePromise<SummaryProcessingStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/summary/status',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Event Summary Html
     * @param eventId
     * @param summaryHtml
     * @returns any Successful Response
     * @throws ApiError
     */
    public editEventSummaryHtml(
        eventId: string,
        summaryHtml: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{event_id}/summary/html',
            path: {
                'event_id': eventId,
            },
            query: {
                'summary_html': summaryHtml,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Followup Email
     * @param eventId
     * @returns string Successful Response
     * @throws ApiError
     */
    public getEventFollowupEmail(
        eventId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/followup/email',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch Event Followup Email
     * @param eventId
     * @param updatedEmail
     * @returns any Successful Response
     * @throws ApiError
     */
    public patchEventFollowupEmail(
        eventId: string,
        updatedEmail: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{event_id}/followup/email',
            path: {
                'event_id': eventId,
            },
            query: {
                'updated_email': updatedEmail,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Tasks
     * @param eventId
     * @returns ActionItemList Successful Response
     * @throws ApiError
     */
    public getEventTasks(
        eventId: string,
    ): CancelablePromise<ActionItemList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/tasks',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Tasks
     * @param eventId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateEventTasks(
        eventId: string,
        requestBody: Array<ActionItem>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{event_id}/tasks',
            path: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Condensates
     * @param eventId
     * @returns string Successful Response
     * @throws ApiError
     */
    public getEventCondensates(
        eventId: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/condensates',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Title
     * @param eventId
     * @param eventTitle
     * @returns string Successful Response
     * @throws ApiError
     */
    public updateEventTitle(
        eventId: string,
        eventTitle: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{event_id}/title',
            path: {
                'event_id': eventId,
            },
            query: {
                'event_title': eventTitle,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Event Module
     * @param eventId
     * @param moduleName
     * @param isRerun
     * @returns EventInDb Successful Response
     * @throws ApiError
     */
    public setEventModule(
        eventId: string,
        moduleName: string,
        isRerun: boolean = false,
    ): CancelablePromise<EventInDb> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/{event_id}/module',
            path: {
                'event_id': eventId,
            },
            query: {
                'module_name': moduleName,
                'is_rerun': isRerun,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Calendar Meeting
     * @param meetingId
     * @param overrideShouldRecord
     * @returns EventInDb Successful Response
     * @throws ApiError
     */
    public updateCalendarMeeting(
        meetingId: string,
        overrideShouldRecord: boolean,
    ): CancelablePromise<EventInDb> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/calendar/update',
            query: {
                'meeting_id': meetingId,
                'override_should_record': overrideShouldRecord,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Client Event
     * @param eventId
     * @param clientId
     * @returns any Successful Response
     * @throws ApiError
     */
    public setClientEvent(
        eventId: string,
        clientId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/{event_id}/client',
            path: {
                'event_id': eventId,
            },
            query: {
                'client_id': clientId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Manual Bot
     * @param requestBody
     * @returns Bot Successful Response
     * @throws ApiError
     */
    public createBot(
        requestBody: BotRequest,
    ): CancelablePromise<Bot> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Url For Upload
     * @param fileExtension
     * @param title
     * @param origin
     * @returns EventInDb Successful Response
     * @throws ApiError
     */
    public generateUrlForUpload(
        fileExtension: string,
        title: string,
        origin?: Origin,
    ): CancelablePromise<EventInDb> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/upload/generate_signed_url',
            query: {
                'file_extension': fileExtension,
                'title': title,
                'origin': origin,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Upload Show Event
     * @param eventId
     * @returns any Successful Response
     * @throws ApiError
     */
    public postUploadShowEvent(
        eventId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/upload/post_upload_show_event',
            query: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Run Sentiment Analysis
     * @param eventId
     * @returns SentimentDoc Successful Response
     * @throws ApiError
     */
    public runSentimentAnalysis(
        eventId: string,
    ): CancelablePromise<SentimentDoc> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/events/analysis/sentiment/run',
            query: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sentiment Analysis
     * @param eventId
     * @returns SentimentDoc Successful Response
     * @throws ApiError
     */
    public getSentimentAnalysis(
        eventId: string,
    ): CancelablePromise<SentimentDoc> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/analysis/sentiment',
            query: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Event Share
     * @param eventId
     * @param notify
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public addEventShare(
        eventId: string,
        notify: boolean,
        requestBody: SharedUser,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/share',
            query: {
                'event_id': eventId,
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Permissions
     * @param eventId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateEventPermissions(
        eventId: string,
        requestBody: Array<SharedUser>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/events/share/permissions',
            query: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Speakers And Assignees
     * @param eventId
     * @returns AssigneeInfo Successful Response
     * @throws ApiError
     */
    public getSpeakersAndAssignees(
        eventId: string,
    ): CancelablePromise<Array<AssigneeInfo>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/eventsget_speakers_and_assignees',
            query: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Financials Json
     * @param eventId
     * @returns ClientData Successful Response
     * @throws ApiError
     */
    public getEventFinances(
        eventId: string,
    ): CancelablePromise<ClientData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/events/{event_id}/finances',
            path: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
