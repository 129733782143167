import React, { MutableRefObject, ReactNode, useEffect, useMemo, useState } from 'react'
import { Typography, Box, Card, Skeleton, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'
import 'firebase/auth'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table'
import { WealthboxOutcome, WealthboxWorkflow } from 'client'
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table'
import { validateWorkflowStepsToComplete } from 'service/integrations/wealthboxApi'

interface Props {
  workflowStepsToComplete: WealthboxWorkflow[]
  setWorkflowStepsToComplete: (value: WealthboxWorkflow[]) => void

  workflows?: WealthboxWorkflow[]
  isLoading: boolean

  rowSelection: MRT_RowSelectionState
  setRowSelection: OnChangeFn<RowSelectionState>
  selectedOutcomes: WealthboxOutcome[]
  setSelectedOutcomes: (outcomes: WealthboxOutcome[]) => void
}

// selectedOutcomes are tracked separately from complete step.
// useEffect change of "outcomes" or "complete step", the "setWorkflowStepsToComplete" gets updated by which outcomes are related. 
// every outcome id is unique even on same workflow step of different user.

export default function WealthboxWorkflowsCompleteStep({ workflowStepsToComplete, setWorkflowStepsToComplete, workflows, isLoading, rowSelection, setRowSelection, selectedOutcomes, setSelectedOutcomes }: Props) {
  const [err, setErr] = useState<undefined | Error>()

  useEffect(() => {
    let selectedRowData = table.getSelectedRowModel().rows.map((row) => row.original)

    // add outcome to checked complete step.
    selectedRowData = selectedRowData.map(row => {
      const availableOutcomes = row.active_step?.workflow_outcomes || []
      selectedOutcomes.forEach(selected => {
        const matchedOutcome = availableOutcomes.find(available => available.id === selected.id)
        if (matchedOutcome && row.active_step) {
          // Set the workflow_outcome_selected to the matched selected outcome
          row.active_step.workflow_outcome_selected = selected
        }
      })
      return row
    })

    setWorkflowStepsToComplete(selectedRowData)

    const validateSteps = validateWorkflowStepsToComplete(selectedRowData)
    setErr(validateSteps)

  }, [rowSelection, selectedOutcomes])

  const columns: MRT_ColumnDef<WealthboxWorkflow>[] = [
    {
      accessorKey: 'linked_to.name', // access nested data with dot notation
      header: 'Related To',
      size: 120,
    },
    {
      accessorKey: 'active_step.name',
      header: 'Next Step',
      Cell: CellName,
      size: 180,
    },
    {
      accessorKey: 'active_step.assigned_to_name',
      header: 'Assigned To',
      size: 100,
    },
    {
      accessorKey: 'outcomes',
      header: 'Outcomes',
      size: 120,
      Cell: (props) => <CellOutcomes {...props} selectedOutcomes={selectedOutcomes} setSelectedOutcomes={setSelectedOutcomes} />,
    }
  ]

  const table = useMaterialReactTable({
    columns,
    data: workflows ?? [],
    enableFullScreenToggle: false,
    positionGlobalFilter: 'left',
    enableHiding: false,
    enableDensityToggle: false,
    enableSelectAll: false,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    initialState: {
      density: 'compact',
      columnOrder: [
        ...columns.map((col) => col.accessorKey as string),
        'mrt-row-select', // ID of the selection column
      ],
      pagination: {
        pageIndex: 0,
        pageSize: 5, // Set initial rows per page to 5
      },
    },
    localization: {
      select: 'Complete Step', // Change 'Select' or 'Actions' to 'Complete Step'
    },
  })

  return (
    <Box>
      <Typography>Select which workflow steps to complete</Typography>
      <Box sx={{ padding: 1 }} />
      {isLoading
        ? <Skeleton variant="rectangular" width={'100%'} height={100} />
        : <MaterialReactTable table={table} />
      }
      <Typography color={'tomato'}>{err?.message}</Typography>
    </Box>
  )
}

interface CellOutcomesProps {
  renderedCellValue: ReactNode
  row: MRT_Row<WealthboxWorkflow>
  selectedOutcomes: WealthboxOutcome[]
  setSelectedOutcomes: (outcomes: WealthboxOutcome[]) => void
}

function CellOutcomes({ row, selectedOutcomes, setSelectedOutcomes }: CellOutcomesProps) {
  const available = row.original.active_step?.workflow_outcomes
  const match = available?.find(avail => selectedOutcomes?.some(selected => selected.id === avail.id))

  async function onChangeOutcome(event: SelectChangeEvent<string>) {
    if (!available)
      return
    const selected: WealthboxOutcome = JSON.parse(event.target.value)

    // remove all options(unique) from 1 workflow 
    const updatedOutcomes = selectedOutcomes.filter(outcome =>
      !available.some(av => av.id === outcome.id)
    )

    setSelectedOutcomes([...updatedOutcomes, selected])
  }

  if (!available || available.length == 0)
    return <></>

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        labelId="outcomes"
        id="outcomes"
        value={match ? JSON.stringify(match) : ''}
        onChange={onChangeOutcome}
        MenuProps={{ sx: { width: 200 } }}
      >
        {available?.map(a =>
          <MenuItem key={a.id} value={JSON.stringify(a)} sx={{ whiteSpace: "normal" }} >
            {a.name}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

interface CellNameProps {
  renderedCellValue: ReactNode
  row: MRT_Row<WealthboxWorkflow>
}

function CellName({ row }: CellNameProps) {
  const maxLength = 40

  let workflowName = row.original.name ?? ''
  workflowName = workflowName.length > maxLength ? workflowName.slice(0, maxLength) + "..." : workflowName

  let step = row.original.active_step?.name ?? ''
  step = step.length > maxLength ? step.slice(0, maxLength) + "..." : step
  return (
    <>
      <Typography variant='caption'>
        {workflowName}
      </Typography>
      <Typography>
        {step}
      </Typography>
    </>
  )
}
