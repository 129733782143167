import React, { MutableRefObject, useEffect, useMemo, useState } from 'react'
import { Typography, Box, Card, Skeleton, Tab } from '@mui/material'
import 'firebase/auth'
import { ClientDetail, WealthboxOutcome, WealthboxVisibility, WealthboxWorkflow } from 'client'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import WealthboxWorkflowsStart from './WealthboxWorkflowsStart'
import WealthboxWorkflowsCompleteStep from './WealthboxWorkflowsCompleteStep'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table'
import { getWealthboxWorkflows, sortWealthboxWorkflows } from 'service/integrations/wealthboxApi'
import { useQuery } from '@tanstack/react-query'

interface Props {
  client?: ClientDetail
  wealthboxId?: number
  visibility: WealthboxVisibility
  workflowStart: MutableRefObject<WealthboxWorkflow[]>
  workflowStepsToComplete: WealthboxWorkflow[]
  setWorkflowStepsToComplete: (value: WealthboxWorkflow[]) => void
}

export default function WealthboxWorkflows({ client, wealthboxId, workflowStart, workflowStepsToComplete, setWorkflowStepsToComplete, visibility }: Props) {
  const [value, setValue] = useState("1")

  const [rowSelectionStart, setRowSelectionStart] = useState<MRT_RowSelectionState>({})

  const [rowSelectionCompleteStep, setRowSelectionCompleteStep] = useState<MRT_RowSelectionState>({})
  const [selectedOutcomesCompleteStep, setSelectedOutcomesCompleteStep] = useState<WealthboxOutcome[]>([])

  const { data: workflows, isLoading: isLoadingWorkflows } = useQuery({
    queryKey: ['getWealthboxWorkflows'],
    queryFn: async () => {
      const res = await getWealthboxWorkflows()
      return sortWealthboxWorkflows(res, client, wealthboxId)
    }
  })

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Card>
      <Typography variant='h5'>Workflows</Typography>

      <TabContext value={value}>

        <TabList onChange={handleChange} aria-label="templates tabs" sx={{ paddingLeft: "14px" }}>
          <Tab label="Start New" value="1" />
          <Tab label="Complete a Step" value="2" />
        </TabList>

        <TabPanel value={"1"} sx={{ p: 1 }} >
          <WealthboxWorkflowsStart
            client={client}
            workflowStart={workflowStart}
            visibility={visibility}
            rowSelection={rowSelectionStart}
            setRowSelection={setRowSelectionStart}
          />
        </TabPanel>

        <TabPanel value={"2"} sx={{ p: 1 }} >
          <WealthboxWorkflowsCompleteStep
            workflowStepsToComplete={workflowStepsToComplete}
            setWorkflowStepsToComplete={setWorkflowStepsToComplete}

            workflows={workflows}
            isLoading={isLoadingWorkflows}

            rowSelection={rowSelectionCompleteStep}
            setRowSelection={setRowSelectionCompleteStep}
            selectedOutcomes={selectedOutcomesCompleteStep}
            setSelectedOutcomes={setSelectedOutcomesCompleteStep}
          />
        </TabPanel>

      </TabContext>

    </Card>
  )
}