/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_sendToWealthbox } from '../models/Body_sendToWealthbox';
import type { WealthboxBase } from '../models/WealthboxBase';
import type { WealthboxContact } from '../models/WealthboxContact';
import type { WealthboxContactType } from '../models/WealthboxContactType';
import type { WealthboxProfile } from '../models/WealthboxProfile';
import type { WealthboxVisibility } from '../models/WealthboxVisibility';
import type { WealthboxWorkflow } from '../models/WealthboxWorkflow';
import type { WealthboxWorkflowTemplate } from '../models/WealthboxWorkflowTemplate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WealthboxService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Authenticate
     * @param code
     * @returns any Successful Response
     * @throws ApiError
     */
    public authWealthBox(
        code: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/wealthbox/auth',
            query: {
                'code': code,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disconnect
     * @returns any Successful Response
     * @throws ApiError
     */
    public disconnectWealthBox(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/wealthbox/auth',
        });
    }

    /**
     * Authenticate
     * @returns WealthboxProfile Successful Response
     * @throws ApiError
     */
    public profile(): CancelablePromise<WealthboxProfile> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/wealthbox/profile',
        });
    }

    /**
     * Get Contacts
     * @param email
     * @returns WealthboxContact Successful Response
     * @throws ApiError
     */
    public getContact(
        email: string,
    ): CancelablePromise<WealthboxContact> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/wealthbox/contacts',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Import Contacts
     * @param contactType
     * @returns number Successful Response
     * @throws ApiError
     */
    public importWealthboxContacts(
        contactType: WealthboxContactType,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/wealthbox/contacts/import',
            query: {
                'contact_type': contactType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send To Wealthbox
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public sendToWealthbox(
        requestBody: Body_sendToWealthbox,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/wealthbox/send',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Config
     * @returns WealthboxBase Successful Response
     * @throws ApiError
     */
    public getWealthboxConfig(): CancelablePromise<WealthboxBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/wealthbox/config',
        });
    }

    /**
     * Set Visibility
     * @param visibility
     * @returns any Successful Response
     * @throws ApiError
     */
    public setVisibility(
        visibility: WealthboxVisibility,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/wealthbox/config/visibility',
            query: {
                'visibility': visibility,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Show Recurring Calender Events
     * @param showRecurring
     * @returns any Successful Response
     * @throws ApiError
     */
    public setShowRecurringCalEvents(
        showRecurring: boolean,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/wealthbox/config/calendar/recurring/show',
            query: {
                'show_recurring': showRecurring,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Wealthbox Workflow Templates
     * @returns WealthboxWorkflowTemplate Successful Response
     * @throws ApiError
     */
    public getWealthboxWorkflowTemplates(): CancelablePromise<Array<WealthboxWorkflowTemplate>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/wealthbox/workflow_templates',
        });
    }

    /**
     * Get Wealthbox Workflows
     * @returns WealthboxWorkflow Successful Response
     * @throws ApiError
     */
    public getWealthboxWorkflows(): CancelablePromise<Array<WealthboxWorkflow>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/wealthbox/workflows',
        });
    }

}
